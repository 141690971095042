<template>
  <div>
    <v-container fluid>
      <v-widget title="Pedidos de Compra">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-select label="Exibição" :items="lista_status" flat single-line hide-details solo v-model="status" @change="filtrarPedidos($event)" background-color="grey lighten-3"></v-select>
              </v-col>
              <v-col cols="2" sm="7" class="text-right">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="total" :headers="headers" :items="pedidos_compra" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">{{ item.numero }}</td>
                <td class="text-center">{{ item.data | formatarData }}</td>
                <td class="text-center">
                  <v-chip pill outlined
                    ><v-avatar left :color="definirCor(item.status)">{{ definirLetra(item.status) }}</v-avatar
                    >{{ item.status }}</v-chip
                  >
                </td>
                <td class="text-center">{{ item.entrega | formatarData }}</td>
                <td>{{ item.participante.razaoSocialNome }}</td>
                <td class="text-center">{{ item.quantidade | formatarMoeda }}</td>
                <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition" @click.native.stop="">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item :key="item._id + 5" @click="abrirEntrega(item)" data-cy="editar">
                        <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
                        <v-list-item-title>Entregar</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item :key="item._id + 10" @click="editar(item)" data-cy="editar">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <v-dialog v-model="dialogEntrega" persistent max-width="1200px" scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Entregar Pedido de Compra</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="1">
              <v-text-field persistent-placeholder outlined v-model="pedido_compra.numero" label="Número" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="2">
              <campo-data label="Data" persistent-placeholder outlined v-model="pedido_compra.data" ref="data" readonly></campo-data>
            </v-col>
            <v-col cols="5">
              <v-text-field persistent-placeholder outlined v-model="pedido_compra.participante.razaoSocialNome" label="Fornecedor" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="pedido_compra.quantidade" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor" persistent-placeholder outlined v-model="pedido_compra.valor" :decimais="2" readonly></as-campo-valor>
            </v-col>
          </v-row>

          <v-divider class="mt-1 mb-1"></v-divider>

          <v-row dense>
            <v-col cols="12">
              <v-data-table :headers="headers_produtos" :items="pedido_compra.produtos" hide-default-footer disable-pagination fixed-header height="300px">
                <template v-slot:item="props">
                  <tr>
                    <td class="text-center">{{ props.item.codigo }}</td>
                    <td>{{ props.item.descricao }}</td>
                    <td>{{ props.item.cliente }}</td>
                    <td class="text-center">{{ props.item.nota_fiscal }}</td>
                    <td class="text-center">{{ props.item.entrega | formatarData }}</td>
                    <td class="text-center">{{ props.item.previsao | formatarData }}</td>
                    <td class="text-center">{{ props.item.quantidade | formatarValor }}</td>
                    <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
                    <td class="text-center">{{ props.item.subtotal | formatarMoeda }}</td>
                    <td class="text-center"><v-icon @click="entregarProduto(props.item)">mdi-check</v-icon></td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEntrega = false" text>Cancelar</v-btn>
          <v-btn color="primary" @click="atualizarPedidoCompra">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEntregaProduto" persistent max-width="800px" scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Entregar Pedido de Compra</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="2">
              <v-text-field persistent-placeholder outlined v-model="produto.codigo" label="Código" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field persistent-placeholder outlined v-model="produto.descricao" label="Descrição" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Quantidade" persistent-placeholder outlined v-model="produto.quantidade" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor" persistent-placeholder outlined v-model="produto.valor" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Subtotal" persistent-placeholder outlined v-model="produto.subtotal" :decimais="2" readonly></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field persistent-placeholder outlined v-model="produto.nota_fiscal" label="Nota Fiscal" autocomplete="off" ref="nota_fiscal" @keypress.native.enter="$refs.entrega.focus()"></v-text-field>
            </v-col>
            <v-col cols="4">
              <campo-data label="Entrega" persistent-placeholder outlined v-model="produto.entrega" ref="entrega" @keypress.native.enter="$refs.previsao.focus()"></campo-data>
            </v-col>
            <v-col cols="4">
              <campo-data label="Previsão" persistent-placeholder outlined v-model="produto.previsao" ref="previsao" @keypress.native.enter="salvarProduto"></campo-data>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEntregaProduto = false" text>Cancelar</v-btn>
          <v-btn color="primary" @click="salvarProduto">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import { clone } from '@/util/helpers';

export default {
  name: 'ListaPedidosCompra',
  data() {
    return {
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        { text: 'Número', value: 'numero', sortable: false, width: '5%', align: 'center' },
        { text: 'Data', value: 'data', sortable: false, width: '10%', align: 'center' },
        { text: 'Status', value: 'status', sortable: false, width: '10%', align: 'center' },
        { text: 'Entrega', value: 'entrega', sortable: false, width: '10%', align: 'center' },
        { text: 'Fornecedor', value: 'fornecedor', sortable: false, width: '40%' },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '10%', align: 'center' },
        { text: 'Valor', value: 'valor', align: 'center', sortable: false, width: '10%' },
        { text: 'Ações', value: 'acoes', align: 'center', sortable: false, width: '5%' },
      ],
      pedido_compra: {
        numero: '',
        participante: {
          razaoSocialNome: '',
        },
        data: '',
        entrega: '',
        quantidade: 0,
        empresa: '',
        observacao: '',
        valor: 0,
        produtos: [],
        status: '',
        frete: 0,
        desconto: 0,
        duplicatas: [],
      },
      dialogEntrega: false,
      headers_produtos: [
        { text: 'Código', value: 'codigo', sortable: false, width: '5%', align: 'center' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '25%' },
        { text: 'Cliente', value: 'cliente', sortable: false, width: '25%' },
        { text: 'Nota Fiscal', value: 'cliente', sortable: false, width: '5%' },
        { text: 'Entrega', value: 'cliente', sortable: false, width: '10%' },
        { text: 'Previsão', value: 'cliente', sortable: false, width: '10%' },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: '5%', align: 'center' },
        { text: 'Valor', value: 'valor', sortable: false, width: '5%', align: 'center' },
        { text: 'Subtotal', value: 'subtotal', sortable: false, width: '5%', align: 'center' },
        { text: 'Entregar', value: 'acoes', sortable: false, width: '7%', align: 'center' },
      ],
      produto: {},
      dialogEntregaProduto: false,
      produto_index: -1,
      lista_status: [
        {
          text: 'Todos',
          value: '',
        },
        {
          text: 'Pendentes',
          value: 'Pendente',
        },
        {
          text: 'Entregues',
          value: 'Entregue',
        },
      ],
      status: '',
    };
  },
  computed: {
    ...mapState('pedidosCompra', {
      pedidos_compra: 'itens',
      carregando: 'carregando',
      total: 'total',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    filtrarPedidos(event) {
      let newOptions = {
        ...this.options,
        query: this.status,
      };

      this.$store.dispatch('pedidosCompra/listar', newOptions);
    },
    limparBusca() {
      this.listar();
    },
    novo() {
      this.$store.commit('pedidosCompra/ZERAR');
      this.$router.push({ name: 'PedidosCompraNovo' });
    },
    listar() {
      this.$store.dispatch('pedidosCompra/listar', this.options);
    },
    editar(pedido_selecionado) {
      this.$store.commit('pedidosCompra/SET_ITEM', pedido_selecionado);
      this.$router.push({ name: 'PedidosCompraEditar' });
    },
    excluir(pedidocompra_id) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir este pedido?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('pedidosCompra/excluir', pedidocompra_id);
        }
      });
    },
    abrirEntrega(pedido_selecionado) {
      this.pedido_compra = clone(pedido_selecionado);
      this.dialogEntrega = true;
    },
    entregarProduto(produto_selecionado) {
      this.produto_index = this.pedido_compra.produtos.findIndex((produto) => produto._id == produto_selecionado._id);
      this.produto = clone(produto_selecionado);
      this.dialogEntregaProduto = true;
      setTimeout(() => {
        this.$refs.nota_fiscal.focus();
      }, 500);
    },
    salvarProduto() {
      this.pedido_compra.produtos.splice(this.produto_index, 1, this.produto);
      this.produto = {};
      this.produto_index = -1;
      this.dialogEntregaProduto = false;
      this.checarStatusPedido();
    },
    checarStatusPedido() {
      // procura produtos que ainda nao foram entregues
      let produto_pendente = this.pedido_compra.produtos.find((produto) => {
        return !produto.entrega;
      });

      if (!produto_pendente) {
        //nao tem nenhum produto pendente de entrega
        this.pedido_compra.status = 'Entregue'; //seta o pedido como entregue
      }
    },
    async atualizarPedidoCompra() {
      await this.$store.dispatch('pedidosCompra/atualizar', this.pedido_compra);
      this.dialogEntrega = false;
    },
    definirCor(status) {
      if (status == 'Entregue') {
        return 'green';
      } else if (status == 'Pendente') {
        return 'white';
      }
    },
    definirLetra(status) {
      if (status) {
        return status.charAt(0);
      } else {
        return '';
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
